import { replaceSpecialCharacters } from "@/utils/stringUtils";
import classNames from "classnames";
import { useRouter } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import BadgeIcon from './badge.svg'
import { useApp } from "@/models/app";
export interface IGoodProps {
  item: any;
}

const GoodCard: React.FC<IGoodProps> = ({ item }) => {
  const router = useRouter();
  const Mapp = useApp();
  const posthog = usePostHog();
  const goDetail = () => {
    posthog.capture("get_detail_page_view", {
      asin: item?.original_id,
      item_id: item?.ID,
    });
    const result = item.product_title
      ?.trim()
      .match(/\b\w+\b/g)
      .join("-");
    const url = new URL(`/good/${result}`, window.location.origin);
    url.searchParams.set("original_id", item.original_id);
    url.searchParams.set("ID", item.ID);
    url.searchParams.set("ref_platform_id", item.ref_platform_id);
    router.push(url.pathname + url.search);
  };
  return (
    <div className="panel" onClick={goDetail}>
      {!!item.redTip?.content && (
        <div className="red-tip">{item.redTip?.content}</div>
      )}
      <div className="pic-wrap">
        <img src={item.product_photo} alt="" />
        <div className="feature-icon ">
          <div
            className={classNames({
              "icon-feature-rating":
                item.product_star_rating <= 4.5 && !item.product_original_price,
              "icon-feature-mostPopular": item.product_num_ratings > 100000,
              "icon-feature-mostComments": false,
              "icon-feature-limited": false,
              "icon-feature-hot":
                (item.rank || 0) < 10 || item.product_star_rating > 4.5,
              "icon-feature-gifts": false,
              "icon-feature-bigCut": !!item.product_original_price,
            })}
          ></div>
          <p className="feature-detail">{item.sales_volume}</p>
        </div>
      </div>
      <div className="panel-mobile-right">
        <p className="goods-title">{item.product_title}</p>
        <div className="bottom-bar">
          <div className="price-wrap">
            <div className="icon-doller"></div>
            <p className="discounted-price">{item.product_price?.toFixed(1)}</p>
            <p className="original-price">{item.product_original_price}</p>
          </div>
          <div className="tool-wrap">
            <div className={classNames("price-trend", { down: true })}></div>
            <div className="goods-btn-list">
              <div
                onClick={() => {}}
                className="icon-btn icon-noLikeGoods"
              ></div>
              <div
                onClick={() => {}}
                className="icon-btn icon-shareGoods"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoodCard;
