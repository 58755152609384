import classNames from "classnames";
import { Button, Dropdown } from "antd";
import { UpOutlined } from "@ant-design/icons";

export interface IShowPosition {
  fixed?: boolean;
  block?: boolean;
}
const Footer: React.FC<IShowPosition> = ({ fixed, block }) => {
  return (
    <div
      className={classNames("footer-container", {
        show: fixed,
        showBlock: block,
      })}
    >
      <div id="footer" className={classNames("footer")}>
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label:<a href="https://x.com/IItopk97792" target="_blank">Twitter</a>,
                onClick: () => {
                },
              },
              {
                key: "2",
                label:<a href="https://www.youtube.com/@itopick" target="_blank">YouTube</a>,
                onClick: () => {
                },
              },
            ],
          }}
          placement="bottomLeft"
        >
          <Button type="link">
            Follow Us <UpOutlined />
          </Button>
        </Dropdown>

        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: <a href="/static/aboutus">About Us</a>,
                onClick: () => {},
              },
              {
                key: "2",
                label: <a href={`mailto:help@itopick.com`}>Contact Us</a>,
                onClick: () => {},
              },
            ],
          }}
          placement="bottomLeft"
        >
          <Button type="link">
            About <UpOutlined />
          </Button>
        </Dropdown>
        <a href="/static/privcacypolicy">Privacy Policy</a>
        <a href="/static/terms">Terms</a>
        <a href="/static/disclaimer">
          Disclaimer
        </a>
        <a href="/static/copyright">
          Copyright Notice
        </a>
        <a href="/static/useragreement">
          User Agreement
        </a>
        {/* <div id="footerCloseBtn" className="icon-btn icon-close"></div> */}
      </div>
    </div>
  );
};

export default Footer;
