"use client";
import Banner1 from "@/assets/images/banner-4.png";
import Banner2 from "@/assets/images/banner-5.png";
import Banner3 from "@/assets/images/banner-6.png";

import GoodCard from "@/components/GoodCard";
import { menuData } from "@/requests/menu";
import {
  apiGetPageCategory,
  apiGetPageProduct,
  apiGetPromotionProduct,
} from "@/requests/product";
import { useRequest } from "ahooks";
import classNames from "classnames";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useApp } from "@/models/app";
import Footer from "@/components/Footer";
import "./index.scss"

const pageSize = 20;
const HomePage: React.FC = observer(() => {
  const router = useRouter();
  const path=usePathname()?.replaceAll("_"," ")?.replace("/","")
  const MApp = useApp();
  const [dataAll, setData] = useState<any[]>([]);
  const page = useRef(1);
  const total = useRef(0);
  const categoryId = useRef<any>();
  const [hasMore, setHasMore] = useState(true);
  useEffect(()=>{
    const categoryItem=MApp.menus?.find(i=>i.name == path)
    if(!categoryItem && MApp.menus?.length){
      router.push("/")
    }else{
      categoryId.current=categoryItem?.ID
      loadMoreData();
    }
  },[MApp.menus])

  const [isSearchResult, setIsSearchResult] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  
  // const [selectedCategory, setSelectedCategory] = useState<string>(path);
  const { data, run, loading } = useRequest(
    (params) => apiGetPageProduct(params),
    {
      manual: true,
      onSuccess: (res: any) => {
        const r = res.data;
        setData((prevData) => [...prevData, ...(r?.list ||[])]);
        total.current = r.total;
        setTotalProducts(r.total);
        page.current += 1;
        if (r.total <= r.page * r.pageSize) {
          setHasMore(false);
        }
      },
    }
  );

  const { data: promotionProduct, run: getPromotionProduct } = useRequest(
    () => apiGetPromotionProduct(),
    { manual: true, onSuccess: (r) => console.log(123, r) }
  );

  const loadMoreData = async () => {
    run({
      page: page.current,
      pageSize,
      category_id: categoryId.current,
      keyword: MApp.productKeyword,
    });
  };
  const scrollContainerRef = useRef<Array<HTMLDivElement | null>>([]);

  const goNext = (index: number) => {
    const container = scrollContainerRef.current[index];
    if (container) {
      container.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };
  
  const goPrev = (index: number) => {
    const container = scrollContainerRef.current[index];
    if (container) {
      container.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };
  useEffect(() => {
    const handleScroll = (event: any) => {
      const diff = Math.abs(window.innerHeight - (event.target.scrollHeight - event.target.scrollTop));
      if (diff < 10) {
        if (
          loading ||
          (page.current - 1) * pageSize >= total.current ||
          total.current == 0
        )
          return;
        loadMoreData();
      }
      if (event.target.scrollTop > 200) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
   
    getPromotionProduct();
    const element: any = document.getElementById("root");
    element?.addEventListener("scroll", handleScroll);
    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  // useEffect(() => {
  //   setData([]);
  //   page.current = 1;
  //   loadMoreData();
  //   setIsSearchResult(!!MApp.productKeyword); // Set based on whether a keyword is present
  // }, [MApp.productKeyword]);
  
  const { t } = useTranslation();
  const carouselImages = [ Banner3.src];
  const [currentIndex, setCurrentIndex] = useState(0);
  const getCarouselStyle = () => {
    return {
      transform: `translateX(-${
        (currentIndex * 100) / carouselImages.length
      }%)`,
      width: `${carouselImages.length * 100}%`,
    };
  };
  const changeSlider = (num: number) => {
    if (num == 1) {
      setCurrentIndex(
        (currentIndex - 1 + carouselImages.length) % carouselImages.length
      );
    } else {
      setCurrentIndex((currentIndex + 1) % carouselImages.length);
    }
  };

  const [showMenu, setShowMenu] = useState(false);
  const toggleMenuLike = (cata: any) => {
    cata.like = !cata.like;
    MApp.setMenus([...MApp.menus]);
  };
  const [subList, setSubList] = useState<any[]>([]);
  const [showChat, setShowChat] = useState(false);
 
 
  const [isFixed, setIsFixed] = useState(false);
  return (
    <div style={{ overflow: showMenu ? "hidden" : "" }}>
      <div
        id="menuContainer"
        className={classNames("menu-container", { show: showMenu })}
      >
        <div className="menu-title">
          <p>Menu</p>
          <div
            className="icon-btn icon-close"
            onClick={() => setShowMenu(false)}
          ></div>
        </div>
        <div id="directoryContainer">
          <div
            id="btnBack"
            onClick={() => {
              subList.pop();
              setSubList([...subList]);
            }}
            className={classNames("btn-back", { show: !!subList?.length })}
          >
            BACK
          </div>

          <ul id="catagoryList">
            {(!!subList?.length
              ? (subList[subList.length - 1] as any[])
              : MApp.menus
            )?.map((cata: any, index) => {
              return (
                <li key={"menu" + index}>
                  <div
                    onClick={() => toggleMenuLike(cata)}
                    className="cata-list-btn btn-like"
                  >
                    <div
                      className={classNames("icon-btn", {
                        "icon-noLikeGoods": !cata.like,
                        "icon-likeGoods": cata.like,
                      })}
                    ></div>
                  </div>
                  <a
                    className={classNames("cata-title", {
                      selected: path == cata.name,
                    })}
                    onClick={() => {
                      MApp.setProductKeyword('')
                      router.push("/" + cata.name?.replace(/ /g,"_"));
                      // setSelectedIndex(index + 1);
                      // searchByCategory(cata.ID, cata.name);
                    }}
                  >
                    {cata.name}
                  </a>
                  {!!cata.subs?.length && (
                    <div
                      className="cata-list-btn btn-enter"
                      onClick={() => {
                        subList?.push(cata.subs);
                        setSubList([...subList]);
                      }}
                    >
                      <div className="icon-btn icon-enter"></div>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="layout-container">
        <div className="main-container">
          {/* <div className="side-bar">
            <div id="openTopyBtn">
              <div className="icon-topy"></div>
            </div>
            <div id="openLikeBtn">
              <div className="icon-like"></div>
            </div>
            <div id="openRankBtn">
              <div className="icon-rank"></div>
            </div>
            <div id="openWishlistBtn">
              <div className="icon-wishlist"></div>
            </div>
            <div id="toTopBtn">
              <div className="icon-toTop"></div>
            </div>
          </div> */}
          <div
            className={classNames("secondery-nav user-select-none", {
              fixed: isFixed,
            })}
          >
            <div
              className="cata-btn"
              id="cataBtn"
              onClick={() => setShowMenu(!showMenu)}
            >
              <div className="icon-list"></div>
            </div>
            <div id="seconderyNavTab" className="secondery-nav-tab">
              {[{ name: t("Home.Label.My") }]
                .concat(
                  path && path !== t("Home.Label.My")
                    ? [{ name: path }]
                    : []
                )
                .concat(
                  MApp.menus
                    ?.filter((item: any) => item.name !== path)
                    .slice(0, path ? 4 : 5)
                )
                ?.map((item: any, index) => {
                  return (
                    <p
                      key={"menu1" + index}
                      onClick={() => {
                        MApp.setProductKeyword('')
                        if(index===0){
                          router.push("/");
                        }else{
                          router.push("/" + item.name?.replace(/ /g,"_"));
                        }
                        // MApp.setProductKeyword("")
                        // setSelectedIndex(index);
                        // searchByCategory(item.ID, item.name);

                      }}
                      className={classNames({
                        selected: path === item.name,
                      })}
                    >
                      {item.name}
                    </p>
                  );
                })}
            </div>
          </div>

          <div className="carousel-container">
            <div className="carousel-inner" style={getCarouselStyle()}>
              {carouselImages.map((imageSrc: any, index) => (
                <img
                  key={index}
                  src={imageSrc}
                  alt={`Slide ${index + 1}`}
                  className="carousel-image"
                  style={{ width: `${100 / carouselImages.length}%` }}
                />
              ))}
            </div>
            <div className="carousel-btn">
              <button
                onClick={() => changeSlider(-1)}
                className="carousel-button"
              >
                &#10094;
              </button>
              <button
                onClick={() => changeSlider(1)}
                className="carousel-button"
              >
                &#10095;
              </button>
            </div>
          </div>
          {
            !isSearchResult && (!path || path=="For you") && (
              <div id="hotsaleList"> 
              {promotionProduct?.data?.list?.map((i: any, index) => {
                  return (
                    <div data-content="promotedSec" className="promoted-goods-sec" key={index}>
                      <p className="module-title">{i.title}</p>
                      <div id="promotedContainer" className="promoted-container">
                        <button
                          id="prevButton"
                          className="promoted-pager"
                          onClick={() => goPrev(index)}
                        >
                          <i className="icon icon-prev"></i>
                        </button>
                        <div
                          id={"scrollableArea" + index}
                          className="scrollable-area"
                          ref={(el) => {
                            scrollContainerRef.current[index] = el;
                          }}
                        >
                          <div className="promoted-panel">
                            {i.products?.slice(0, 12)?.map((item: any, index2: number) => {
                              return <GoodCard item={item} key={"promotion" + index + index2} />;
                            })}
                          </div>
                        </div>
                        <button
                          id="nextButton"
                          className="promoted-pager"
                          onClick={() => goNext(index)}
                        >
                          <i className="icon icon-next"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
          )}

          <div className="module-title">
            {isSearchResult ? (
              <span>
                {totalProducts} results found matching <b>{MApp.productKeyword}</b>
              </span>
            ) : path && path!="For you" ? ("")
              :(
              "New Today"
            )}
          </div>
          <div id="productsList" className="products-list">
          
            {dataAll.map((item: any, index: number) => {
              return <GoodCard item={item} key={"good" + index} />;
            })}
          </div>
          {!hasMore && <p id="datafinishedTip">no more products</p>}

          {loading && (
            <div id="loadingIndicator">
              <div className="loading-circle"></div>
              <div className="loading-circle"></div>
              <div className="loading-circle"></div>
            </div>
          )}
        </div>


        {/* <div id="sideContainer" className={classNames("side-container",{show:showChat})}>
          <button
            id="chatAICloseBtn"
            className="icon-btn icon-close popup-close"
          ></button>
        </div> */}
      </div>
      <Footer fixed={isFixed}/>
    </div>
  );
});

export default HomePage;
